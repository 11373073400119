import React from "react";
import PropTypes from "prop-types";
import { PAGES } from "../../../helpers/constants";
import FormBlockContainer from "../../../containers/common/forms/FormBlockContainer";
import "./index.scss";

const SectionWithForm = ({
  isLoading,
  isSecondStateCollectEmail,
  setIsSecondStateCollectEmail,
}) => {
  return (
    <section className="PgKT-SectionWithForm">
      <h2 className="PgKT-SectionWithForm__title">
        Boost your search rankings so that you can have a best-selling product!
      </h2>
      <FormBlockContainer
        title="Get your free trial today to start ranking higher for profitable search terms"
        isLoading={isLoading}
        pageFormState={isSecondStateCollectEmail}
        updatePageFormState={setIsSecondStateCollectEmail}
        customClass={
          isSecondStateCollectEmail
            ? "PgKT-SectionWithForm-FormBlockContainer_secondState"
            : "PgKT-SectionWithForm-FormBlockContainer"
        }
        buttonTextFirstState="Try AMZScout For Free!"
        buttonTextSecondState="TRY AMZSCOUT FOR FREE!"
        page={PAGES.KEYWORD_TRACKER}
      />
    </section>
  );
};

SectionWithForm.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  isSecondStateCollectEmail: PropTypes.bool.isRequired,
  setIsSecondStateCollectEmail: PropTypes.func.isRequired,
};

export default SectionWithForm;
